import AxiosRequest from '../../utils/axios-request';
import SecureLS from 'secure-ls';
import Moment from 'moment';

export default function (uid, product, options = null, service = null, store = null,
                         foundation = null, sel_pick = false, sel_ship = false,
                         sel_donate = false, address = null, selected_date = null,
                         time_select = null, community = null) {
    let final_data_product, final_data_service, pickup, donate, shipping, total;

    if (sel_pick) {
        pickup = true;
    } else if (sel_ship) {
        if (product) {
            shipping = product.shipping;
        } else if (service) {
            shipping = service.shipping;
        }
    } else if (sel_donate) {
        donate = true;
    }

    if (product && product.options.length && options === null) {
        Swal.fire({
            title: 'Seleccione una opción',
            icon: 'error'
        });
    } else if (service && store === null) {
        Swal.fire({
            title: 'Seleccione una tienda',
            icon: 'error'
        });
    } else if (service && selected_date === null) {
        Swal.fire({
            title: 'Seleccione una fecha',
            icon: 'error'
        });
    } else if (service && time_select === null) {
        Swal.fire({
            title: 'Seleccione una hora',
            icon: 'error'
        });
    } else if (sel_pick === false && sel_ship === false && sel_donate === false) {
        Swal.fire({
            title: 'Seleccione un tipo de envío',
            icon: 'error'
        });
    } else if (parseInt(this.quantity) === 0) {
        Swal.fire({
            title: 'La cantidad a comprar no puede ser zero',
            icon: 'error'
        });
    } else {
        if (product) {
            final_data_product = {
                type: product.type_id,
                title: product.title,
                image: product.image,
                limit: options ? options.unit : product.units,
                price: product.price,
                options: options,
                quantity: parseInt(this.quantity),
                product_id: product.id,
                address_id: address,
                store_id: parseInt(store),
                foundation_id: parseInt(foundation),
                pickup: pickup,
                shipping: shipping,
                donate: donate,
                total_discounts: product.discounts,
                total_taxes: product.taxes,
                total: product.price * parseInt(this.quantity),
                customer_id: community
            };
        } else if (service) {
            total = service.price;
            if (this.isHoliday(this.date) && this.isSameOrAfter(this.last_service, this.time_select.main)) {
                total = service.price + service.night_surcharge + service.holiday_surcharge;
            } else if (this.isHoliday(this.date)) {
                total = service.price + service.holiday_surcharge;
            } else if (this.isSameOrAfter(this.last_service, this.time_select.main)) {
                total = service.price + service.night_surcharge;
            }
            final_data_service = {
                type: service.type_id,
                title: service.title,
                image: service.image,
                price: total,
                normal_price: service.price,
                service_id: service.id,
                address_id: address,
                store_id: store,
                foundation_id: foundation,
                pickup: pickup,
                shipping: shipping,
                donate: donate,
                date_text: Moment(this.date).lang('es').format('MMMM D YYYY'),
                date: Moment(this.date).format(),
                time_select: this.time_select.main,
                time_text: this.time_select.hour,
                holiday_surcharge: this.isHoliday(this.date) ? service.holiday_surcharge : 0,
                night_surcharge: this.isSameOrAfter(this.last_service, this.time_select.main)
                    ? service.night_surcharge
                    : 0,
                isHoliday: this.isHoliday(this.date),
                isSameOrAfter: this.isSameOrAfter(this.last_service, this.time_select.main),
                total_discounts: service.discounts,
                total_taxes: service.taxes,
                total: total,
                customer_id: community
            };
        }
        AxiosRequest(
            '/customers/shopping_carts/add.json',
            {
                product: product.id,
                quantity: this.quantity,
                service: service.id
            },
            'post'
        ).then(({data}) => {
            var actual_cart, cart_some, item, ls, servi_some, units, value, _ref;
            ls = new SecureLS();
            actual_cart = ls.get('cart');
            if (!actual_cart) {
                actual_cart = {};
            }
            if (!actual_cart[uid]) {
                actual_cart[uid] = [];
            }
            if (product) {
                cart_some = actual_cart[uid].some(function (e) {
                    return (
                        e.product_id === product.id && e.options.product_inventory_id === options.product_inventory_id
                    );
                });
                if (cart_some) {
                    _ref = actual_cart[uid];
                    for (item in _ref) {
                        value = _ref[item];
                        units = options ? options.unit : product.units;
                        if (value.product_id === product.id) {
                            if (value.quantity < units) {
                                if (value.options && (value.options.any_options = true)) {
                                    if (value.options.product_inventory_id === options.product_inventory_id) {
                                        value.quantity += parseInt(this.quantity);
                                    }
                                } else {
                                    value.quantity += parseInt(this.quantity);
                                }
                                Swal.fire({
                                    title: data.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    inputAttributes: {
                                        autocapitalize: 'off'
                                    },
                                    footer:
                                        "<div class='uk-flex uk-flex-wrap uk-flex-around'> <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a> <a class='uk-button uk-button-secondary white-text ' href='/productos' data-turbolinks='false'>Ver más productos</a> </div>"
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Error',
                                    text: 'Número de unidades no es válido (Unidades disponibles: ' + units + ')'
                                });
                            }
                        }
                    }
                } else {
                    actual_cart[uid].push(final_data_product);
                    Swal.fire({
                        title: data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        footer:
                            "<div class='uk-flex uk-flex-wrap uk-flex-around'> <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a> <a class='uk-button uk-button-secondary white-text' href='/productos' data-turbolinks='false'>Ver más productos</a> </div>"
                    });
                }
            } else if (service) {
                servi_some = actual_cart[uid].some(function (e) {
                    return (
                        e.service_id === service.id &&
                        Moment(e.date).format('YYYY-MM-DD') === Moment(this.date).format('YYYY-MM-DD') &&
                        Moment(e.time_select).format('h:mm A') === Moment(this.time_select.main).format('h:mm A') &&
                        e.store_id === selected_store_id
                    );
                });
                if (servi_some) {
                    Swal.fire({
                        title: 'Este service ya esta agendado',
                        icon: 'error',
                        showConfirmButton: false,
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        footer:
                            "<div class='uk-flex uk-flex-wrap uk-flex-around'> <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a> <a class='uk-button uk-button-secondary white-text' href='/servicios' data-turbolinks='false'>Ver más servicios</a> </div>"
                    });
                } else {
                    actual_cart[uid].push(final_data_service);
                    Swal.fire({
                        title: data.message,
                        icon: 'success',
                        showConfirmButton: false,
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        footer:
                            "<div class='uk-flex uk-flex-wrap uk-flex-around'> <a class='uk-button uk-button-primary white-text uk-margin-right' href='/customers/shopping_carts'>Ir al carrito</a> <a class='uk-button uk-button-secondary white-text' href='/servicios' data-turbolinks='false'>Ver más servicios</a> </div>"
                    });
                }
            }
            return ls.set('cart', actual_cart);
        });
    }
};