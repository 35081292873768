#External Libraries
import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import VueAutonumeric from 'vue-autonumeric'
import UniqBy from 'lodash.uniqby'

# Local imports
import fetchProducts from '../methods/products/fetch-products'
import AddCart from '../methods/products/add_cart'
import Delivery from '../methods/products/delivery'
import DeleteFavourite from '../methods/items/delete_favourite'
import Favourite from '../methods/items/favourite'
import toCurrency from '../filters/to_currency'
import AxiosRequest from "../utils/axios-request"

Vue.use(TurbolinksAdapter)
document.addEventListener 'turbolinks:load', () ->
  el = document.getElementById 'index-product'
  return false unless el?

  url_mode = (property) ->
    resp = false
    resp = el.dataset[property] if el.dataset.hasOwnProperty(property)
    resp
  data = ->
    { searchParams } = new URL(window.location.href)
    # Default values
    categories = []
    causes = []
    subcategories = []
    page = 1
    discounts = window.location.pathname == '/descuentos'
    sort =
      updated_at: 'desc'
      public_price_published_price: false
      item_title : 'desc'
    # Url params
    categories_param = parseInt(url_mode('category') || searchParams.get('category'))
    subcategories_param = parseInt(url_mode('subcategory') || searchParams.get('subcategory'))
    causes_param = parseInt(url_mode('cause') || searchParams.get('cause'))

    # Negotiation
    categories.push(categories_param) if categories_param
    causes.push(causes_param) if causes_param
    subcategories.push(subcategories_param) if subcategories_param
    page |= parseInt searchParams.get('page')
    keywords = searchParams.get('keyword')
    discounts |= parseInt searchParams.get('discounts')

    {
      favourites: []
      products: []
      default_option: []
      ob_product: undefined
      filter_interactions: 1
      subcategories: []
      subcategory_names: []
      list_mode: 'grid'
      page
      selected_option: undefined
      tmp_option_selected: undefined
      selected_shipping: undefined
      selected_pickup: undefined
      selected_donate: undefined
      selected_foundation_id: undefined
      selected_store_id: undefined
      selected_address: undefined
      price_shipping: 0
      quantity: 1
      min: 1
      max: 0
      loading: true
      search_filters: {
        categories
        causes
        keywords
        discounts
        subcategories
        price_from: ''
        price_to: ''
      }
      sort
    }
  new Vue {
    el
    data
    mounted: ->
      @getSubcategories(@search_filters.categories, false)
      @GetFavourite()
    methods: {
      toggleListMode: ->
        @list_mode = if @list_mode is 'grid' then 'list' else 'grid'
        @filtersChanged()
      fetchProducts
      AddCart
      Delivery
      Favourite
      DeleteFavourite
      filtersChanged: ->
        window.scrollTo(0, 0)
        @page = 1
        @fetchProducts()
      assignProperty: (property, value) ->
        @search_filters[property] = value
      clearProperty: (property, reload = true) ->
        @search_filters[property] = '';
        @fetchProducts() if reload
      infiniteHandler: ($state) ->
        @fetchProducts('infinite', $state)
      resetState: ->
        history.pushState({}, 'Ver Todos Nuestros Productos', document.location.origin + document.location.pathname)
        Object.assign @$data, data()
      getSubcategories: (categories, launch_control = true) ->
        @search_filters.subcategories = [] if launch_control
        subcats = []
        if categories.length
          for category in categories
            AxiosRequest("/subcategories/#{category}.json").then ({ data }) =>
              subcats.push(data...)
              @subcategories = UniqBy(subcats, 'id')
        else
          @subcategories = []
      sortControl: (order) ->
        @sort = order
        @filtersChanged()
      canAdd: (options = null, default_option) ->
        if options
          if @selected_pickup
            flag = true if @selected_option == options.product_inventory_id
          else if @selected_shipping
            flag = true if @selected_option == options.product_inventory_id
          else if @selected_donate
            flag = true if @selected_option == options.product_inventory_id
        else if default_option == true
          if @selected_pickup
            flag = true
          else if @selected_shipping
            flag = true
          else if @selected_donate
            flag = true
        else
          flag = false
        not (flag and @quantity >= 1)
      GetFavourite: ->
        query = $.get "/productos.json"
        query.done (res) =>
          @favourites = res.favourites
      productFavourite: (product, uid) ->
        unless ! uid
          @favourites?.some((e) => (e.item_id == product))
      ReloadFavourite: ->
        @GetFavourite()
    }
    watch: {
      tmp_option_selected: (val) ->
        @max = @ob_product.options[val].unit
        @selected_shipping = false
        @selected_pickup = false
        @selected_donate = false
        @selected_store_id = undefined
        @selected_foundation_id = undefined
      max: (val) ->
        if @tmp_option_selected != undefined
          if @quantity >= @max
            @quantity = val
      quantity: (val) ->
        if @quantity >= @max
          @quantity = @max
      'search_filters.categories': (categories) ->
        @filtersChanged()
        @getSubcategories(categories)
      'search_filters.subcategories': -> @filtersChanged()
      'search_filters.causes': -> @filtersChanged()
      'search_filters.price_from': -> @filtersChanged()
      'search_filters.price_to': -> @filtersChanged()
      'search_filters.keywords': -> @filtersChanged()
      'search_filters.discounts': -> @filtersChanged()
    }
    components: {
      InfiniteLoading
      VueAutonumeric
    }
    computed: {
      active_price_filters: ->  @search_filters.price_from || @search_filters.price_to || @search_filters.discounts
      active_filters: ->
        f = @search_filters
        f.price_from || f.price_to || f.categories.length || f.keywords || f.discounts || f.causes.length
      any_delivery: ->
        flag = false
        if @selected_shipping == true || @selected_pickup == true || @selected_donate == true
          flag = true
        flag
    }
    filters: {
      toCurrency
    }
  }
