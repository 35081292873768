import AxiosRequest from "../../utils/axios-request"
import SecureLS from 'secure-ls';
import UiKit from 'uikit';

export default (item, uid, service, product) ->
  self = @
  Swal.fire(
    title: '¿Estás seguro?'
    text: ''
    icon: 'warning'
    showCancelButton: true
    confirmButtonColor: '#30bbe5'
    cancelButtonColor: '#d33'
    cancelButtonText: 'Cancelar'
    confirmButtonText: 'Sí, quitar favorito').then (result) =>
      if result.value
        self.ReloadFavourite()
        self.GetFavourite()
        if product
          UiKit.modal(document.getElementById('product-modal-' + item)).hide();
        if service
          UiKit.modal(document.getElementById('service-modal-' + item)).hide();
        AxiosRequest("/customers/favourites/remove", {item_id: item, customer_id: uid}, 'post').then ({data}) =>
          Swal.fire({
            title: data.message
            icon: 'success'
            toast: true
            position: 'top-end'
            timer: 2000
          })
      else if result.dismiss == Swal.DismissReason.cancel
        Swal.fire({
          title: 'Cancelado'
          icon: 'error'
          toast: true
          position: 'top-end'
          timer: 2000
        }).then ->
          console.log 'Cancelado'
      return
