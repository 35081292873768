import AxiosRequest from "../../../utils/axios-request";

export default function (product, uid, self) {
    let message, value;

    value = new Intl.NumberFormat({
        style: 'decimal',
        maximu4mSignificantDigits: 3
    }).format(product.shipping.price);
    if (product.shipping.free) {
        message = "El valor del Domicilio es Gratis";
    } else {
        message = "El valor del Domicilio es $ " + value;
    }
    AxiosRequest("/customers/shopping_carts/addresses.json", {
        idcustomer: uid
    }).then(function (_arg) {
        let address, data, states;
        data = _arg.data;
        if (uid === 'joker') {
            return Swal.fire({
                icon: 'info',
                text: 'Para añadir este producto a domicilio, debes iniciar sesión',
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                width: '60%',
                showCancelButton: true,
                allowOutsideClick: false
            }).then(async function (res) {
                if (res.value) {
                    self.selected_shipping = res.value;
                    await AxiosRequest("/return_to_item", {
                        original_url: window.location.href
                    }, "post");
                    return window.location.replace(data.url);
                } else {
                    return self.selected_shipping = false;
                }
            });
        } else {
            if (data.address) {
                address = data.address.map((function (_this) {
                    return function (e) {
                        return "<option value='" + e.id + "'>" + e.full + "</option>";
                    };
                })(this));
                return Swal.fire({
                    title: '',
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    allowOutsideClick: false,
                    showCancelButton: true,
                    width: '60%',
                    html: "<p>" + message + "</p> <label for='uid_address' class='uk-text-left'> Selecciona una dirección </label> <select name='uid_address' id='uid_address' class='uk-select swal2-input'> <option value='0'>Selecciona una dirección de envio</option>" + address + "</select>",
                    preConfirm: function () {
                        var udi_address;
                        udi_address = document.getElementById('uid_address').value;
                        if (udi_address <= 0) {
                            return Swal.showValidationMessage('Selecciona una dirección');
                        }
                    }
                }).then(function (res) {
                    let s_address;
                    if (res.value) {
                        s_address = document.getElementById('uid_address').value;
                        self.selected_address = s_address;
                        return self.selected_shipping = res.value;
                    } else {
                        return self.selected_shipping = false;
                    }
                });
            } else {
                states = data.states.map((function (_this) {
                    return function (e) {
                        return "<option value='" + e.idstate + "'>" + e.name + "</option>";
                    };
                })(this));
                return Swal.fire({
                    icon: 'error',
                    title: data.message,
                    confirmButtonText: 'Agregar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                    allowOutsideClick: false,
                    width: '60%',
                    html: "<div class='uk-text-left'> <label for='address' class='uk-form-label uk-text-bolder'>Dirección</label> <input type='text' name='address' id='address' class='uk-input swal2-input uk-margin-auto-vertical' placeholder=''> <label for='neighborhood' class='uk-form-label uk-text-bolder'>Barrio</label> <input type='text' name='neighborhood' id='neighborhood' class='uk-input swal2-input uk-margin-auto-vertical' placeholder=''> <div data-controller='master-detail' data-master-detail-victim='#city_id' data-master-detail-url='/states'> <label for='state_id' class='uk-form-label uk-text-bolder'>Departamento</label> <select id='state_id' class='swal2-input uk-input uk-select uk-margin-auto-vertical' data-target='master-detail.master' data-action='master-detail#setVictims'> <option value=''>Seleccione una opción</option>" + states + "</select> </div> <label for='city_id' class='uk-form-label uk-text-bolder'>Ciudad</label> <select id='city_id' class='swal2-input uk-input uk-select uk-margin-auto-vertical'> <option value=''>Seleccione una opción</option> </select> <label for='name_address' class='uk-form-label uk-text-bolder'>Nombre para identificar esta dirección</label> <input type='text' name='name_address' id='name_address' class='uk-input swal2-input uk-margin-auto-vertical' placeholder='Ej. Mi Casa..'> </div>",
                    footer: "<a href='" + data.url + "' class='uk-button blue-button'>Agregar otra dirección</a>",
                    preConfirm: function () {
                        var city_id, name, neighborhood, state_id;
                        name = document.getElementById('name_address').value;
                        address = document.getElementById('address').value;
                        neighborhood = document.getElementById('neighborhood').value;
                        state_id = document.getElementById('state_id').value;
                        city_id = document.getElementById('city_id').value;
                        if (name === '' || address === '' || neighborhood === '' || state_id === '' || city_id === '') {
                            return Swal.showValidationMessage('Dedes llenar los campos');
                        } else if (name.length > 45) {
                            return Swal.showValidationMessage('Nombre para identificar esta dirección es demasiado largo');
                        } else if (address.length > 90) {
                            return Swal.showValidationMessage('Dirección es demasiada larga');
                        } else if (neighborhood.length > 45) {
                            return Swal.showValidationMessage('Barrio es demasiado largo');
                        }
                    }
                }).then(function (res) {
                    let city_id, name, neighborhood, state_id;
                    if (res.value) {
                        self.selected_shipping = res.value;
                        name = document.getElementById('name_address').value;
                        address = document.getElementById('address').value;
                        neighborhood = document.getElementById('neighborhood').value;
                        state_id = document.getElementById('state_id').value;
                        city_id = document.getElementById('city_id').value;
                        address = {
                            name: name,
                            address: address,
                            neighborhood: neighborhood,
                            state_id: state_id,
                            city_id: city_id,
                            customer_id: uid
                        };
                        return AxiosRequest('/customers/shopping_carts/add_address', {
                            address: address
                        }, 'post').then(function (_arg1) {
                            let data;
                            data = _arg1.data;
                            return self.selected_address = data.idaddress;
                        });
                    } else {
                        return self.selected_shipping = false;
                    }
                });
            }
        }
    });
};