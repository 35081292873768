import AxiosRequest from '../../utils/axios-request';
import pickup from "./deliveries/pickup";
import shipping from "./deliveries/shipping";
import donate from "./deliveries/donate";
import shipping_service from "./deliveries/shipping_service";
// import SecureLS from 'secure-ls'

export default function (product = null, delivery_type = null, service = null, uid = 'joker') {
    let request, self;
    self = this;
    self.delivery_type = delivery_type;
    self.selected_pickup = false;
    self.selected_shipping = false;
    self.selected_donate = false;
    self.selected_store_id = null;
    self.selected_foundation_id = null;

    if (product) {
        if (delivery_type === 'pickup') {
            pickup(product, self);
        } else if (delivery_type === 'donate') {
            donate(product, self);
        } else if (delivery_type === 'shipping') {
            shipping(product, uid, self)
        }
    } else if (service) {
        if (delivery_type === 'shipping') {
            shipping_service(service, uid, self)
        } else if (delivery_type === 'pickup') {
            let store = service.stores.find((e) => e.store_id === self.selected_store_id);
            request = Swal.fire({
                title: 'Servicio: ' + service.title,
                text: 'Tienda: ' + store.store_name,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                allowOutsideClick: false,
                width: '60%'
            });
            request.then((res) => {
                if (res.value) {
                    self.selected_pickup = res.value;
                    return (self.price_shipping = 0);
                } else {
                    return (self.selected_pickup = res.value);
                }
            });
        } else if (delivery_type === 'donate') {
            let foundation = service.foundations.find((e) => e.foundation_id === self.selected_foundation_id);
            request = Swal.fire({
                title: 'Servicio: ' + service.title,
                text: 'Fundación: ' + foundation.store_name,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
                allowOutsideClick: false,
                width: '60%'
            });
            request.then((res) => {
                if (res.value) {
                    self.selected_donate = res.value;
                    return (self.price_shipping = 0);
                } else {
                    return (self.selected_donate = res.value);
                }
            });
        }
    }
}