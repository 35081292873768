export default function (product, self) {
    let option_store, request, stores;

    stores = product.store; // Array de tiendas
    option_store = stores.map((function (_this) {
        return function (el) {
            return "<option value=\"" + el.store_id + "\">" + el.store_name + "</option>";
        };
    })(this));
    request = Swal.fire({
        title: "Seleccionar tienda o sede de recogida",
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        width: '60%',
        showCancelButton: true,
        allowOutsideClick: false,
        html: "<label for='store'>Tienda o sede de recogida</label> <select id='store' class='swal2-input' name='store'><option value=''>Seleccione...</option>" + option_store + "</select>",
        preConfirm: function () {
            let store;
            store = document.getElementById('store').value;
            if (store === '') {
                return Swal.showValidationMessage('Selecciona una tienda o sede de recogida');
            }
        }
    });
    request.then((function (_this) {
        return function (res) {
            if (res.value) {
                self.selected_pickup = res.value;
                return self.selected_store_id = document.getElementById('store').value;
            } else {
                return self.selected_pickup = false;
            }
        };
    })(this));
};