export default function (product, self) {
    let foundations, option_foundation, request;

    foundations = product.foundation; // Array de fundaciones
    option_foundation = foundations.map((function (_this) {
        return function (el) {
            return "<option value=\"" + el.foundation_id + "\">" + el.foundation_name + "</option>";
        };
    })(this));
    request = Swal.fire({
        title: "Seleccionar fundación para la donación",
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        width: '60%',
        showCancelButton: true,
        allowOutsideClick: false,
        html: "<label for='foundation'>Fundación</label> <select id='foundation' class='swal2-input' name='foundation'><option value=''>Seleccione...</option>" + option_foundation + "</select>",
        preConfirm: function () {
            let foundation;
            foundation = document.getElementById('foundation').value;
            if (foundation === '') {
                return Swal.showValidationMessage('Selecciona una fundación.');
            }
        }
    });
    request.then((function (_this) {
        return function (res) {
            if (res.value) {
                self.selected_donate = res.value;
                return self.selected_foundation_id = document.getElementById('foundation').value;
            } else {
                return self.selected_donate = false;
            }
        };
    })(this));
};